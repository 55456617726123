(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('UserController', UserController);

    UserController.$inject = ['$scope', '$http', 'AlertService'];

    function UserController ($scope, $http, AlertService) {
        var vm = this;

        vm.ansvarFilter = {
            ansvar: 'Alle ansvar'
        };

        loadData();

        function loadData() {
            // Do not fucking use let here. Will completely crash when running in PROD profile.
            var ansvar = 'all';
            if (vm.ansvarFilter.ansvar !== 'Alle ansvar') {
                ansvar = vm.ansvarFilter.ansvar;
            }
            $http.get('/api/usermembers/' + ansvar).then(function (result) {
                vm.userMembers = result.data.members;
                vm.userMembersAnsvar = result.data.membersAnsvar;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste brukere. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }

        function generateRandomPassword() {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (var i = 0; i < 8; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        }

        $scope.showProjectMemberModal = function(projectMember) {
            // Polyfill (to make the Object.assign work on IE) snatched from here:
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
            if (typeof Object.assign != 'function') {
                // Must be writable: true, enumerable: false, configurable: true
                Object.defineProperty(Object, "assign", {
                    value: function assign(target, varArgs) { // .length of function is 2
                        'use strict';
                        if (target == null) { // TypeError if undefined or null
                            throw new TypeError('Cannot convert undefined or null to object');
                        }

                        var to = Object(target);

                        for (var index = 1; index < arguments.length; index++) {
                            var nextSource = arguments[index];

                            if (nextSource != null) { // Skip over if undefined or null
                                for (var nextKey in nextSource) {
                                    // Avoid bugs when hasOwnProperty is shadowed
                                    if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                                        to[nextKey] = nextSource[nextKey];
                                    }
                                }
                            }
                        }
                        return to;
                    },
                    writable: true,
                    configurable: true
                });
            }

            $scope.projectMember = {};
            if (!projectMember) {
                $scope.projectMember.activated = true;
                $scope.projectMember.password = generateRandomPassword();
                $scope.projectMember.timesheetReminder = true;
            }
            Object.assign($scope.projectMember, projectMember);
            $('#projectMemberModal').modal('show');
        };

        $scope.saveProjectMember = function() {
            $http({
                method: 'POST',
                url: 'api/members',
                data: $scope.projectMember
            }).then(function (response) {
                if ($scope.projectMember.userId) {
                    AlertService.success("Bruker oppdatert.");
                } else {
                    AlertService.success("Ny bruker opprettet.");
                }
                loadData();
                $('#projectMemberModal').modal('hide');
            }, function (response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#projectMemberModal').modal('hide');
            });
        };

        $scope.updateAnsvarFilter = function(ansvar) {
            vm.ansvarFilter.ansvar = ansvar;
            loadData();
        };

    }
})();
