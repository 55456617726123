(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('InvoiceApprovalController', InvoiceApprovalController);

    InvoiceApprovalController.$inject = ['$scope', '$http', 'AlertService'];

    function InvoiceApprovalController ($scope, $http, AlertService) {
        var vm = this;

        // Find last day of previous month.
        var invoiceDate = moment()
            .subtract(1,'months')
            .endOf('month');

        $('#invoiceDateDropdown').datetimepicker({
            format: 'DD.MM.YYYY',
            locale: 'nb',
            defaultDate: invoiceDate
        });

        loadData(invoiceDate);

        $scope.setInvoiceDate = function () {
            $("#invoiceDateDropdown").datetimepicker().on("dp.change", function (data) {
                loadData(data.date);
            });
        };

        $scope.isCollapsed = function (index) {
            // TODO: Improve this.
            return $("#collapse" + (index + 1)).attr("class").indexOf('in') >= 0;
        };

        $scope.loadProjectDetails = function (index, project) {
            // Only get accounting details when opening the collapsable panel.
            if (!$scope.isCollapsed(index)) {
                $http.get('/api/project/invoice/accounting/' + project.projectId + '/' + project.periodFrom + '/' + project.periodTo).then(function (result) {
                    project.accounting = result.data;
                }, function (result) {
                    console.log(result);
                    alert('Klarte ikke laste posteringer for prosjektet. Prøv igjen og gi beskjed til din ' +
                        'kontaktperson i DevelopIT dersom problemet vedvarer.');
                });
            }
        };

        function loadData(invoiceDate) {
            $http.get('/api/project/invoice/approval/' + invoiceDate.format("YYYY-MM-DD")).then(function (result) {
                vm.approvals = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste fakturagodkjenning. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }
    }
})();
