(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('TimesheetController', TimesheetController);

    TimesheetController.$inject = ['$scope', '$http', 'AlertService'];

    function TimesheetController ($scope, $http, AlertService) {
        var vm = this;

        vm.currentDate = moment();
        vm.awayReasons = [];
        vm.numberOfProjectsToShow = 10;
        vm.numberOfEquipmentToShow = 10;

        // Initialize DTOs.
        vm.timesheetNew = {
            quickAddHours: {
                subProjects: []
            }
        };

        vm.confirmDeleteHoursModal = {
            date: ""
        };

        vm.confirmDeleteEquipmentHoursModal = {
            date: ""
        };

        vm.awayReasons = [
            {
                value: 'selfCertification',
                name: 'Egenmelding'
            }, {
                value: 'sickLeave',
                name: 'Sykemelding'
            }, {
                value: 'course',
                name: 'Kurs/opplæring'
            }, {
                value: 'vacation',
                name: 'Ferie'
            }, {
                value: 'timeOff',
                name: 'Avspasering'
            }, {
                value: 'holiday',
                name: 'Helligdag'
            }, {
                value: 'other',
                name: 'Annet'
            }
        ];

        vm.quickAddHours = {
            date: "",
            projectId: "-1",
            hours: 0,
            hoursSelection: [
                {hours: 0.5, label: "0,5 t"},
                {hours: 1, label: "1 t"},
                {hours: 1.5, label: "1,5 t"},
                {hours: 2, label: "2 t"},
                {hours: 2.5, label: "2,5 t"},
                {hours: 3, label: "3 t"},
                {hours: 3.5, label: "3,5 t"},
                {hours: 4, label: "4 t"},
                {hours: 4.5, label: "4,5 t"},
                {hours: 5, label: "5 t"},
                {hours: 5.5, label: "5,5 t"},
                {hours: 6, label: "6 t"},
                {hours: 6.5, label: "6,5 t"},
                {hours: 7, label: "7 t"},
                {hours: 7.5, label: "7,5 t"}
            ],
            selectedOption: '',
            options: [
                {
                    value: 'EQUIPMENT_AND_HOURS',
                    name: 'Timer og utstyr'
                }, {
                    value: 'HOURS',
                    name: 'Timer'
                }, {
                    value: 'EQUIPMENT',
                    name: 'Utstyr'
                }, {
                    value: 'AWAY',
                    name: 'Fravær'
                }
            ],
            selectedSubProject: '',
            subProjects: [],
            selectedEquipment: '',
            equipments: [],
            selectedAwayReason: ''
        };

        loadData(vm.currentDate);

        $scope.setDateTime = function () {
            $("#datetimepickerStart").datetimepicker().on("dp.change", function (data) {
                vm.currentDate = data.date;
                loadData(vm.currentDate);
            });
        };

        $scope.previousWeek = function () {
            vm.currentDate = vm.currentDate.add(-1, 'week');
            loadData(vm.currentDate);
            $('#datetimepickerStart').data("DateTimePicker").date(vm.currentDate);
        };

        $scope.thisWeek = function () {
            vm.currentDate = moment();
            loadData(vm.currentDate);
            $('#datetimepickerStart').data("DateTimePicker").date(vm.currentDate);
        };

        $scope.nextWeek = function () {
            vm.currentDate = vm.currentDate.add(1, 'week');
            loadData(vm.currentDate);
            $('#datetimepickerStart').data("DateTimePicker").date(vm.currentDate);
        };

        $scope.showConfirmSubmittingHoursModal = function () {
            $('#confirmSubmittingHoursModal').modal('show');
        };

        vm.showConfirmDeleteHoursModal = function (id, date) {
            if (vm.timesheetNew.alreadySubmitted) {
                return;
            }
            vm.confirmDeleteHoursModal.id = id;
            vm.confirmDeleteHoursModal.date = date;
            $('#confirmDeleteHoursModal').modal('show');
        };

        vm.showConfirmDeleteEquipmentHoursModal = function (id, date) {
            if (vm.timesheetNew.alreadySubmitted) {
                return;
            }
            vm.confirmDeleteEquipmentHoursModal.id = id;
            vm.confirmDeleteEquipmentHoursModal.date = date;
            $('#confirmDeleteEquipmentHoursModal').modal('show');
        };

        vm.isAddButtonDisabled = function () {
            if (vm.timesheetNew.alreadySubmitted) {
                return true;
            }
            if (vm.quickAddHours.selectedOption.value === 'AWAY') {
                return vm.quickAddHours.date === ''
                    || vm.quickAddHours.selectedAwayReason === '';
            }
            return vm.quickAddHours.date === ''
                || vm.quickAddHours.projectId === ''
                || vm.quickAddHours.hours === 0
                || vm.quickAddHours.selectedOption === '';
        };

        /** Function for checking if selected projectId is listed in subProjects. */
        vm.isProjectIdIncludedInSubProjects = function () {
            for (var i = 0; i < vm.timesheetNew.quickAddHours.subProjects.length; i++) {
                if (vm.timesheetNew.quickAddHours.subProjects[i].projectId === vm.quickAddHours.projectId) {
                    return true;
                }
            }
            return false;
        };

        $scope.submitHours = function () {
            $http({
                method: 'POST',
                url: 'api/timesheet/submit/' + vm.currentDate.format('DD-MM-YYYY')
            }).then(function (response) {
                vm.alreadySubmitted = true;
                loadData(vm.currentDate);
                AlertService.success("Timene er nå sendt inn til godkjenning");
            }, function (response) {
                alert("Klarte ikke å sende inn timene. Sjekk loggen!");
                console.log(response);
            });
            $('#confirmSubmittingHoursModal').modal('hide');
        };

        function loadData(date) {
            $http.get('/api/timesheet/new/' + date.format('DD-MM-YYYY')).then(function (result) {
                vm.timesheetNew = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste timene dine for denne uken. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }

        /** Function for saving hours for the quick add form. */
        vm.saveHours = function () {
            if (vm.quickAddHours.selectedOption.value === 'AWAY') {
                console.log(vm.quickAddHours.date);
                console.log(vm.quickAddHours.selectedAwayReason.value);
                $http({
                    method: 'POST',
                    url: 'api/timesheet/submit/awayReason',
                    data: {
                        awayReasons: [{
                            date: vm.quickAddHours.date,
                            reason: vm.quickAddHours.selectedAwayReason.value
                        }]
                    }
                }).then(function (response) {
                    vm.quickAddHours.date = "";
                    vm.quickAddHours.projectId = "";
                    vm.quickAddHours.hours = 0;
                    vm.quickAddHours.selectedOption = '';
                    vm.quickAddHours.selectedSubProject = '';
                    vm.quickAddHours.selectedEquipment = '';
                    loadData(vm.currentDate);
                }, function (response) {
                    alert("Klarte ikke å sende inn timene. Sjekk loggen!");
                    console.log(response);
                });
            } else {
                $http({
                    method: 'POST',
                    url: 'api/timesheet/hours',
                    data: {
                        date: vm.quickAddHours.date,
                        projectId: vm.quickAddHours.projectId,
                        hours: vm.quickAddHours.hours,
                        subProjectId: vm.quickAddHours.selectedSubProject.id,
                        equipmentId: vm.quickAddHours.selectedEquipment.id,
                        type: vm.quickAddHours.selectedOption.value
                    }
                }).then(function (response) {
                    vm.quickAddHours.date = "";
                    vm.quickAddHours.projectId = "";
                    vm.quickAddHours.hours = 0;
                    vm.quickAddHours.selectedOption = '';
                    vm.quickAddHours.selectedSubProject = '';
                    vm.quickAddHours.selectedEquipment = '';
                    loadData(vm.currentDate);
                    AlertService.success("Timer er registrert");
                }, function (response) {
                    alert("En feil oppstod. Sjekk loggen!");
                    console.log(response);
                });
            }
        };

        /** Function for deleting project member hours. */
        vm.deleteMemberHours = function (id) {
            $http({
                method: 'DELETE',
                url: 'api/timesheet/memberHours/' + id
            }).then(function (response) {
                vm.confirmDeleteHoursModal.date = "";
                $('#confirmDeleteHoursModal').modal('hide');
                loadData(vm.currentDate);
                AlertService.success("Timer er slettet");
            }, function (response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        /** Function for deleting project equipment hours. */
        vm.deleteEquipmentHours = function (id) {
            $http({
                method: 'DELETE',
                url: 'api/timesheet/equipmentHours/' + id
            }).then(function (response) {
                vm.confirmDeleteEquipmentHoursModal.date = "";
                $('#confirmDeleteEquipmentHoursModal').modal('hide');
                loadData(vm.currentDate);
                AlertService.success("Timer er slettet");
            }, function (response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

    }
})();
