(function() {
    'use strict';

    angular
        .module('projectManagementApp', [
            'ngStorage',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            //'ui.bootstrap.datetimepicker',
            'ui.router',
            'ui.router.state.events',
            'rzModule',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'moment-picker',
            'ngMap',
            'chart.js'
        ])
        .run(run);

    run.$inject = ['stateHandler', '$rootScope'];

    function run(stateHandler, $rootScope) {
        stateHandler.initialize();

        // Always scroll to top of screen on page changes.
        // eslint-disable-next-line angular/on-watch
        $rootScope.$on('$stateChangeSuccess', function() {
            // eslint-disable-next-line angular/document-service
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        });
    }
})();
