(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('VismaTestController', VismaTestController);

    VismaTestController.$inject = ['$scope', '$http', 'AlertService'];

    function VismaTestController ($scope, $http, AlertService) {
        var vm = this;

        vm.voucher = setupVoucher();
        vm.result = {
            request: "",
            response: ""
        };

        $('#dateDropdown').datetimepicker({
            format: 'DD.MM.YYYY',
            locale: 'nb',
            defaultDate: vm.voucher.voucherDate
        });

        vm.setDate = function () {
            $("#dateDropdown").datetimepicker().on("dp.change", function (data) {
                vm.voucher.voucherDate = data.date;
            });
        };

        vm.createVoucher = function () {
            $http.post('/api/visma/createVoucher', vm.voucher)
                .then(function(response) {
                    vm.voucher = setupVoucher();
                    vm.result.request = response.data.request;
                    vm.result.response = response.data.response;
                })
                .catch(function(error) {
                    console.error('POST error', error);
                });
        };

        function setupVoucher() {
            return {
                company: 1,
                division: 0,
                approveVoucher: false,
                updateVoucher: false,
                voucherType: 71,
                period: moment().month() + 1, // Zero-based, so need to add 1.
                voucherDate: moment(),
                transaction1: {
                    art: 1242,
                    ansvar: 4318,
                    tjeneste: 3340,
                    prosjekt: 485052,
                    aktivitet: null,
                    arbeidsordre: null,
                    taxCode: null,
                    voucherText: 'Test fra API',
                    baseAmount: -1.01
                },
                transaction2: {
                    art: 1242,
                    ansvar: 4305,
                    tjeneste: 3340,
                    prosjekt: 630502,
                    aktivitet: null,
                    arbeidsordre: null,
                    taxCode: null,
                    voucherText: 'Test fra API',
                    baseAmount: 1.01
                }
            };
        }

    }
})();
